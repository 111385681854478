<template>
  <div>
    <div class="card-body">

        <div class="row">
          <div class="w-100" style="text-align:center">
            <h2>Android</h2>
          </div>
          <div class="d-flex w-100 align-items-end mb-1">

            <div class="w-100 mr-2" >
              <label>Build Version</label>
              <input v-model="currentSetting.value.android.build_version" type="text" class="form-control" id="buildVersion" placeholder="Build Version">
            </div>

            <div class="w-100 mr-2">
              <label>Release Version</label>
              <input v-model="currentSetting.value.android.release_version" type="text" class="form-control" id="releaseVersion" placeholder="Release Version">
            </div>

            <div class="w-100 mr-2" >
              <label>Update Required Status</label>
              <input v-model="currentSetting.value.android.update_required_status" type="text" class="form-control" id="updateRequiredStatus" placeholder="Update Required Status">
            </div>

          </div>

        </div>
        <div class="row">
          <div class="w-100" style="text-align:center">
            <h2>ios</h2>
          </div>
          <div class="d-flex w-100 align-items-end mb-1">

            <div class="w-100 mr-2" >
              <label>Build Version</label>
              <input v-model="currentSetting.value.ios.build_version" type="text" class="form-control" id="iosBuildVersion" placeholder="Build Version">
            </div>

            <div class="w-100 mr-2">
              <label>Release Version</label>
              <input v-model="currentSetting.value.ios.release_version" type="text" class="form-control" id="iosReleaseVersion" placeholder="Release Version">
            </div>

            <div class="w-100 mr-2" >
              <label>Update Required Status</label>
              <input v-model="currentSetting.value.ios.update_required_status" type="text" class="form-control" id="iosUpdateRequiredStatus" placeholder="Update Required Status">
            </div>

          </div>

        </div>
        <div class="d-flex w-100 align-items-center justify-content-end px-3">
          <div>
            <button :disabled="isUpdateButtonDisable" @click="updateSettingsHandler" class="btn btn-primary px-lg-2" type="submit">Update</button>
          </div>

        </div>
      </div>
    </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";


export default {
  name: "SettingDefaultTechnicianMobApp",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['defaultTechnicianMobApp'],
  data() {
    return {
      currentSetting: this.defaultTechnicianMobApp,
    };
  },
  computed: {
    isUpdateButtonDisable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null || !this.can('setting-default-update');
    }
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Technician mob app updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>

</style>