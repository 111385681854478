<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
      <div class="mob-app-wrapper">

        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
          <h4 class="">Default Mob App/List</h4>
        </div>

            <div class="accordion" id="mobAppAccordion">

              <div class="card">
                <div class="card-header" id="settingDefaultTechnicianMobAppHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultTechnicianMobAppCollapse" aria-expanded="false" aria-controls="collapseDefaultTechnicianMobApp">

                    <span class="text-capitalize mob-app-setting-title"> Technician Mob App</span>

                  </button>
                </div>
                <div id="settingDefaultTechnicianMobAppCollapse" class="collapse" aria-labelledby="settingDefaultTechnicianMobAppHeading" data-parent="#mobAppAccordion">
                  <div class="card-body">
                    <SettingDefaultTechnicianMobApp v-if="Object.keys(this.defaultTechnicianMobApp?.value ?? {}).length > 0"
                                                    :default-technician-mob-app="this.defaultTechnicianMobApp"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultCustomerMobAppHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultCustomerMobAppCollapse" aria-expanded="false" aria-controls="collapseDefaultCustomerMobApp">

                    <span class="text-capitalize mob-app-setting-title">  Customer Mob App</span>

                  </button>
                </div>
                <div id="settingDefaultCustomerMobAppCollapse" class="collapse" aria-labelledby="settingDefaultCustomerMobAppHeading" data-parent="#mobAppAccordion">
                  <div class="card-body">
                    <SettingDefaultCustomerMobApp v-if="Object.keys(this.defaultCustomerMobApp?.value ?? {}).length > 0" :default-customer-mob-app="this.defaultCustomerMobApp"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultCustomerAppImageHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultCustomerAppImageCollapse" aria-expanded="false" aria-controls="collapseDefaultCustomerAppImage">
                    <span class="text-capitalize mob-app-setting-title">Customer App Image</span>

                  </button>
                </div>
                <div id="settingDefaultCustomerAppImageCollapse" class="collapse" aria-labelledby="settingDefaultCustomerAppImageHeading" data-parent="#mobAppAccordion">
                  <div class="card-body">
                    <SettingDefaultCustomerAppImage :default-customer-app-image="this.defaultCustomerAppImage"/>
                  </div>
                </div>
              </div>

            </div>

      </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import SettingDefaultTechnicianMobApp from "@/views/backEnd/settings/default/mobApps/includes/SettingDefaultTechnicianMobApp";
import SettingDefaultCustomerMobApp from "@/views/backEnd/settings/default/mobApps/includes/SettingDefaultCustomerMobApp";
import SettingDefaultCustomerAppImage from "@/views/backEnd/settings/default/mobApps/includes/SettingDefaultCustomerAppImage";

export default {
  name: "SettingDefaultMobAppList.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingDefaultTechnicianMobApp,
    SettingDefaultCustomerMobApp,
    SettingDefaultCustomerAppImage,

  },
  data() {
    return {
      getSettingsParams: {
        type: ["default"],
        key: [
          "default_technician_mob_app",
          "default_customer_mob_app",
          "default_customer_app_image",
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      defaultCustomerMobApp: 'appSettings/settingDefaultCustomerMobApp',
      defaultTechnicianMobApp: 'appSettings/settingDefaultTechnicianMobApp',
      defaultCustomerAppImage: 'appSettings/settingDefaultCustomerAppImage',

    }),
  },
  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.mob-app-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.mob-app-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
