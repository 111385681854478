<template>
  <div>
    <div class="card-body">

        <div class="row">
          <div class="d-flex w-100 align-items-end mb-1">

            <div class="w-100 mr-1">
              <img class="img-fluid" style="height: 80px" :src="currentSetting.value.help_page_banner" alt="">
            </div>

            <div class="w-100 mr-1">
              <label></label>
              <input v-model="currentSetting.value.help_page_banner" type="text" class="form-control" id="helpPageBanner" placeholder="Help Page Banner">
            </div>

            <div>
              <button :disabled="isUpdateButtonDisable" @click="updateSettingsHandler" class="btn btn-primary px-lg-2" type="submit">Update</button>
            </div>

          </div>

        </div>

        <div class="row">
          <div class="d-flex w-100 align-items-end mb-1">

            <div class="w-100 mr-1">
              <img class="img-fluid" style="height: 80px" :src="currentSetting.value.home_page_banner" alt="">
            </div>

            <div class="w-100 mr-1">
              <label></label>
              <input v-model="currentSetting.value.home_page_banner" type="text" class="form-control" id="homePageBanner" placeholder="Home Page Banner">
            </div>

            <div>
              <button :disabled="isUpdateButtonDisable" @click="updateHomePageBanner" class="btn btn-primary px-lg-2" type="submit">Update</button>
            </div>

          </div>

        </div>
        <div class="row">
          <div class="d-flex w-100 align-items-end mb-1">

            <div class="w-100 mr-1">
              <img class="img-fluid" style="height: 80px" :src="currentSetting.value.home_page_callback" alt="">
            </div>

            <div class="w-100 mr-1">
              <label></label>
              <input v-model="currentSetting.value.home_page_callback" type="text" class="form-control" id="homePageCallback" placeholder="Home Page Callback">
            </div>

            <div>
              <button :disabled="isUpdateButtonDisable" @click="updateHomePageCallback" class="btn btn-primary px-lg-2" type="submit">Update</button>
            </div>

          </div>

        </div>
        <div class="row">
          <div class="d-flex w-100 align-items-end mb-1">

            <div class="w-100 mr-1">
              <img class="img-fluid" style="height: 80px" :src="currentSetting.value.home_page_membership" alt="">
            </div>

            <div class="w-100 mr-1">
              <label></label>
              <input v-model="currentSetting.value.home_page_membership" type="text" class="form-control" id="homePageMembership" placeholder="Home Page Membership">
            </div>

            <div>
              <button :disabled="isUpdateButtonDisable" @click="updateHomePageMembership" class="btn btn-primary px-lg-2" type="submit">Update</button>
            </div>

          </div>

        </div>
        <div class="row">
          <div class="d-flex w-100 align-items-end mb-1">

            <div class="w-100 mr-1">
              <img class="img-fluid" style="height: 80px" :src="currentSetting.value.service_page_callback" alt="">
            </div>

            <div class="w-100 mr-1">
              <label></label>
              <input v-model="currentSetting.value.service_page_callback" type="text" class="form-control" id="servicePageCallback" placeholder="Service Page Callback">
            </div>

            <div>
              <button :disabled="isUpdateButtonDisable" @click="updateServicePageCallback" class="btn btn-primary px-lg-2" type="submit">Update</button>
            </div>

          </div>

        </div>
        <div class="row">
          <div class="d-flex w-100 align-items-end mb-1">

            <div class="w-100 mr-1">
              <img class="img-fluid" style="height: 80px" :src="currentSetting.value.service_page_guarantee" alt="">
            </div>

            <div class="w-100 mr-1">
              <label></label>
              <input v-model="currentSetting.value.service_page_guarantee" type="text" class="form-control" id="servicePageGuarantee" placeholder="Service Page Guarantee">
            </div>

            <div>
              <button :disabled="isUpdateButtonDisable" @click="updateServicePageGuarantee" class="btn btn-primary px-lg-2" type="submit">Update</button>
            </div>

          </div>

        </div>

      </div>

    </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";


export default {
  name: "SettingDefaultCustomerAppImage",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['defaultCustomerAppImage'],
  data() {
    return {
      currentSetting: this.defaultCustomerAppImage,
    };
  },
  computed: {
    isUpdateButtonDisable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null || !this.can('setting-default-update');
    }
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    buildJsonValue() {
      let JsonObject = {
        help_page_banner: this.currentSetting?.value?.help_page_banner ?? "",
        home_page_banner: this.currentSetting?.value?.home_page_banner ?? "",
        home_page_callback: this.currentSetting?.value?.home_page_callback ?? "",
        home_page_membership: this.currentSetting?.value?.home_page_membership ?? "",
        service_page_callback: this.currentSetting?.value?.service_page_callback ?? "",
        service_page_guarantee: this.currentSetting?.value?.service_page_guarantee ?? "",

      };

      return JSON.stringify(JsonObject);
    },

    async updateSettingsHandler() {
      await this.updateSettings();
    },
    async updateHomePageBanner() {
      await this.updateSettings();
    },
    async updateHomePageCallback() {
      await this.updateSettings();
    },
    async updateHomePageMembership() {
      await this.updateSettings();
    },
    async updateServicePageCallback() {
      await this.updateSettings();
    },
    async updateServicePageGuarantee() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Customer app image updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>

</style>